<template>
  <div class="login-container">
    <transition
      name="sso-card"
      mode="out-in"
    >
      <component :is="stepComponent" />
    </transition>
  </div>
</template>

<script setup>
import {
  computed, defineProps, toRefs, defineAsyncComponent, watch,
} from 'vue';

import { AUTH } from '@js/const';

import useSsoStore from '@js/store/sso.store';
import { storeToRefs } from 'pinia';

const stepComponents = {
  [AUTH.O_AUTH]: defineAsyncComponent(() => import('@js/components/security/steps/SingleSignOnOauthStep.vue')),
  [AUTH.CREDENTIALS]: defineAsyncComponent(() => import('@js/components/security/steps/SingleSignOnLoginStep.vue')),
  [AUTH.GDPR]: defineAsyncComponent(() => import('@js/components/security/steps/SingleSignOnGdprStep.vue')),
  [AUTH.FORGOT]: defineAsyncComponent(() => import('@js/components/security/steps/SingleSignOnForgotStep.vue')),
  [AUTH.RESET]: defineAsyncComponent(() => import('@js/components/security/steps/SingleSignOnResetStep.vue')),
  [AUTH.START]: defineAsyncComponent(() => import('@js/components/security/steps/SingleSignOnStartStep.vue')),
  [AUTH.BLOCKED]: defineAsyncComponent(() => import('@js/components/security/steps/SingleSignOnBlockedStep.vue')),
  [AUTH.ACCESS_DENIED]: defineAsyncComponent(() => import('@js/components/security/steps/SingleSignOnDisabledStep.vue')),
};

const ssoStore = useSsoStore();
const {
  splash, ssoOrigin, logo, step, isBlocked,
} = storeToRefs(ssoStore);

const {
  resetBlockedUntil,
} = ssoStore;

// Mini router
watch(step, (newStep) => {
  let stateUrl = '/';
  if (newStep !== AUTH.O_AUTH) {
    stateUrl = `/${newStep}`;
  }
  window.history.pushState(null, null, `${stateUrl}${window.location.search}`);
});

watch(isBlocked, (blocked) => {
  if (blocked) {
    step.value = AUTH.BLOCKED;
  } else {
    resetBlockedUntil();
  }
}, { immediate: true });

// Props
const props = defineProps({
  ssoOrigin: { type: String, default: null },
  splash: { type: String, default: null },
  logo: { type: String, default: null },
});

const propValues = toRefs(props);

splash.value = propValues.splash.value;
ssoOrigin.value = propValues.ssoOrigin.value ?? 'miadmin';
logo.value = propValues.logo.value;

const stepComponent = computed(() => stepComponents[step.value]);

const stepRouter = () => {
  let path = window.location.pathname.split('/')[1];
  if (path === '') {
    path = AUTH.O_AUTH;
  }
  step.value = path;
};

stepRouter();
window.onpopstate = () => {
  stepRouter();
};

</script>

<style lang="scss" scoped>
.login-container {
  contain: layout;
}
</style>
